<template>
  <v-container>
    <v-dialog v-if="!loggedIn && !accessToken" value="true" width="500" persistent>
      <v-card>
        <v-card-title primary-title>
          <img :src="require('@/assets/shield.svg')" style="margin-right:1em;" contain height="50" alt="Covenant Shield Logo" />
          Board Portal Log In
        </v-card-title>
        <v-card-text>
          <div style="min-height:2em">
            <v-alert v-if="errorMessage" type="error" dense>{{ errorMessage }}</v-alert>
          </div>
          <v-text-field label="Email Address" ref="emailField" v-model="email" required outlined @keyup.enter="checkSubmit()"></v-text-field>
          <v-text-field label="Password" ref="passwordField" type="password" v-model="password" required outlined @keyup.enter="checkSubmit()"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" size="medium" @click="checkSubmit()">Login</v-btn>
          <v-btn to="/board/reset" color="primary" size="mdedium" outlined>Forgot Password</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" size="medium" outlined @click="doCovenantLogin()">Covenant Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-else>
      <v-toolbar class="mb-4">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              {{ selectedText }}
              <v-icon right>fas fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="{ text, type, category } in selectOptions" :key="text" @click="selectedType = type; selectedCategory = category">{{ text }}</v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn v-if="isAdmin" to="/board/admin" outlined>Admin</v-btn>
        <v-menu v-if="selectedCategory === 'files'">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on" style="min-width:150px">
              {{ selectedTerm }}
              <v-icon right>fal fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card style="max-height:500px">
            <v-list>
              <div v-for="{ year, terms: termList } in terms" :key="year">
                <v-subheader style="height: 30px;background-color:#EEE">{{ year }}</v-subheader>
                <v-list-item v-for="{ _id, term } in termList" :key="_id" @click="select({ _id, term })">
                  <v-list-item-title>{{ term }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar>
      <minutes v-if="selectedCategory === 'minutes'" :email="email" :type="selectedType"></minutes>
      <v-row v-else>
        <v-col v-if="col1.length > 0" :cols="12" md="6" :offset-md="col2.length === 0 ? 3 : 0" :lg="col2.length === 0 ? 6 : 5" :offset-lg="col2.length === 0 ? 3 : 1">
          <group-card v-for="({ label, files }, index) in col1" :key="'col1-' + index" :label="label" :files="files" @downloading="(fIndex) => setDownloading(1, index, fIndex, true)" @downloadFinished="(fIndex) => setDownloading(1, index, fIndex, false)"></group-card>
        </v-col>
        <v-col v-if="col2.length > 0" :cols="12" md="6" lg="5">
          <group-card v-for="({ label, files }, index) in col2" :key="'col1-' + index" :label="label" :files="files" @downloading="(fIndex) => setDownloading(2, index, fIndex, true)" @downloadFinished="(fIndex) => setDownloading(2, index, fIndex, false)"></group-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  components: {
    Minutes: () => import('@/components/board/Minutes'),
    GroupCard: () => import('@/components/board/fileGroupCard')
  },
  setup (props, { root }) {
    const loggedIn = computed(() => root.$store.state.loggedIn)
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isAdmin = computed(() => 'Technology Services' in roles.value || 'Presidents Office Staff' in roles.value)
    const accessToken = computed(() => root.$store.state.board.accessToken)
    const selectedType = ref('C')
    const selectedCategory = ref('files')
    const hasCollege = computed(() => root.$store.state.board.college)
    const hasFoundation = computed(() => root.$store.state.board.foundation)
    const selectOptions = computed(() => {
      const arr = []
      if (hasCollege.value) {
        arr.push({ text: 'Covenant College Board Files', type: 'C', category: 'files' })
        arr.push({ text: 'Covenant College Board Minutes', type: 'C', category: 'minutes' })
      }
      if (hasFoundation.value) {
        arr.push({ text: 'Covenant Foundation Board Files', type: 'F', category: 'files' })
        arr.push({ text: 'Covenant Foundation Board Minutes', type: 'F', category: 'minutes' })
      }
      return arr
    })
    const selectedText = computed(() => {
      const arr = selectOptions.value.filter(({ type, category }) => type === selectedType.value && category === selectedCategory.value)
      return arr.length > 0 ? arr[0].text : 'Select Option'
    })
    const fileService = root.$feathers.service('board/files')
    const userService = root.$feathers.service('board/users')
    onMounted(async () => {
      if (!loggedIn.value && !accessToken.value) {
        root.$nextTick(() => {
          root.$store.commit('setSideNavActive', false)
        })
      } else if (loggedIn.value) {
        console.log('Logged in? ' + loggedIn.value)
        const username = user.value.username
        const { data } = await userService.find({ query: { username, active: true } })
        if (data.length === 0) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You do not have permission to access the Board documents. Contact the President\'s Office to request access.' })
          root.$router.replace('/')
        }
        root.$store.commit('board/setUser', { college: data[0].college, foundation: data[0].foundation })
        selectedType.value = data[0].college ? 'C' : 'F'
        load()
      }
    })

    const errorMessage = ref('')
    const email = ref('')
    const emailField = ref(null)
    const password = ref('')
    const passwordField = ref(null)
    async function checkSubmit () {
      if (email.value === '') {
        emailField.value.focus()
        return
      }
      if (password.value === '') {
        passwordField.value.focus()
        return
      }
      const { data } = await userService.find({ query: { email: email.value.toLowerCase(), password: password.value } })
      // console.log(data)
      if (data.length > 0) {
        committees.value = data[0].committees
        email.value = email.value.toLowerCase()
        root.$store.commit('board/setUser', data[0])
        selectedType.value = data[0].foundation ? 'F' : 'C'
        userService.patch(data[0]._id, { lastAccess: new Date(), email: email.value })
        load()
      } else {
        errorMessage.value = 'Invalid login. Either the password is incorrect or the email is not set up in the Board Access list.'
      }
    }

    const selectedTerm = computed(() => root.$store.state.board.selectedTerm || 'Select Term')
    const selectedId = computed(() => root.$store.state.board.selectedId)
    function select ({ _id, term }) {
      console.log(_id, term)
      root.$store.commit('board/setSelectedTerm', { term, id: _id })
    }
    const terms = ref([])
    async function load () {
      const aggregate = [
        { $match: { type: selectedType.value, year: { $ne: 'Minutes' } } },
        { $sort: { year: 1, term: 1 } },
        { $group: {
          _id: '$year',
          terms: {
            $push: { _id: '$_id', term: '$term' }
          }
        } },
        { $project: {
          year: '$_id',
          terms: { $sortArray: { input: '$terms', sortBy: { term: -1 } } }
        } },
        { $sort: { year: -1 } }
      ]
      const query = { aggregate }
      if (accessToken.value != null && accessToken.value !== '') {
        query.accessToken = accessToken.value
        query.email = email.value
      }
      const { data } = await fileService.find({ query })
      if (data.length > 0) {
        terms.value = data
        console.log(data)
        select(data[0].terms[0])
      }
    }

    const committees = ref([])
    const col1 = ref([])
    const col2 = ref([])
    watch(selectedId, (id) => {
      if (id != null) {
        fileService.get(id).then((row) => {
          col1.value = row.col1
          col2.value = row.col2
        })
      }
    })
    watch(selectedType, () => {
      if (loggedIn.value || accessToken.value) {
        console.log('loading')
        load()
      }
    })

    watch([selectedType, selectedCategory], () => {
      if (selectedCategory.value === 'minutes') {
        col1.value = []
        col2.value = []
        const query = { year: 'Minutes', type: selectedType.value }
        if (accessToken.value != null && accessToken.value !== '') {
          query.accessToken = accessToken.value
          query.email = email.value
        }
        console.log(query)
        fileService.find({ query }).then(({ data }) => {
          if (data.length > 0) {
            col1.value = data[0].col1
          }
        })
      } else if (selectedId.value != null) {
        fileService.get(selectedId.value).then((row) => {
          col1.value = row.col1
          col2.value = row.col2
        })
      }
    })

    function setDownloading (col, cardIndex, fileIndex, downloading) {
      if (col === 1) {
        const temp = col1.value[cardIndex]
        const file = { ...temp.files[fileIndex], downloading }
        temp.files.splice(fileIndex, 1, file)
        col1.value.splice(cardIndex, 1, temp)
      } else {
        const temp = col2.value[cardIndex]
        const file = { ...temp.files[fileIndex], downloading }
        temp.files.splice(fileIndex, 1, file)
        col2.value.splice(cardIndex, 1, temp)
      }
    }

    function doCovenantLogin () {
      window.localStorage.setItem('redirectPath', root.$route.path)
      const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
      window.location = basePath + '/saml'
    }

    return {
      loggedIn,
      user,
      roles,
      isAdmin,
      accessToken,
      selectedType,
      selectedCategory,
      hasCollege,
      hasFoundation,
      selectOptions,
      selectedText,
      errorMessage,
      email,
      emailField,
      password,
      passwordField,
      committees,
      checkSubmit,
      selectedTerm,
      selectedId,
      select,
      terms,
      col1,
      col2,
      setDownloading,
      doCovenantLogin
    }
  }
}
</script>
