var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.loggedIn && !_vm.accessToken)?_c('v-dialog',{attrs:{"value":"true","width":"500","persistent":""}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('img',{staticStyle:{"margin-right":"1em"},attrs:{"src":require('@/assets/shield.svg'),"contain":"","height":"50","alt":"Covenant Shield Logo"}}),_vm._v(" Board Portal Log In ")]),_c('v-card-text',[_c('div',{staticStyle:{"min-height":"2em"}},[(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1),_c('v-text-field',{ref:"emailField",attrs:{"label":"Email Address","required":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkSubmit()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{ref:"passwordField",attrs:{"label":"Password","type":"password","required":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkSubmit()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","size":"medium"},on:{"click":function($event){return _vm.checkSubmit()}}},[_vm._v("Login")]),_c('v-btn',{attrs:{"to":"/board/reset","color":"primary","size":"mdedium","outlined":""}},[_vm._v("Forgot Password")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","size":"medium","outlined":""},on:{"click":function($event){return _vm.doCovenantLogin()}}},[_vm._v("Covenant Login")])],1)],1)],1):_c('div',[_c('v-toolbar',{staticClass:"mb-4"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.selectedText)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.selectOptions),function(ref){
var text = ref.text;
var type = ref.type;
var category = ref.category;
return _c('v-list-item',{key:text,on:{"click":function($event){_vm.selectedType = type; _vm.selectedCategory = category}}},[_vm._v(_vm._s(text))])}),1)],1),_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{attrs:{"to":"/board/admin","outlined":""}},[_vm._v("Admin")]):_vm._e(),(_vm.selectedCategory === 'files')?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"min-width":"150px"},attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.selectedTerm)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}],null,false,3978872403)},[_c('v-card',{staticStyle:{"max-height":"500px"}},[_c('v-list',_vm._l((_vm.terms),function(ref){
var year = ref.year;
var termList = ref.terms;
return _c('div',{key:year},[_c('v-subheader',{staticStyle:{"height":"30px","background-color":"#EEE"}},[_vm._v(_vm._s(year))]),_vm._l((termList),function(ref){
var _id = ref._id;
var term = ref.term;
return _c('v-list-item',{key:_id,on:{"click":function($event){return _vm.select({ _id: _id, term: term })}}},[_c('v-list-item-title',[_vm._v(_vm._s(term))])],1)})],2)}),0)],1)],1):_vm._e()],1),(_vm.selectedCategory === 'minutes')?_c('minutes',{attrs:{"email":_vm.email,"type":_vm.selectedType}}):_c('v-row',[(_vm.col1.length > 0)?_c('v-col',{attrs:{"cols":12,"md":"6","offset-md":_vm.col2.length === 0 ? 3 : 0,"lg":_vm.col2.length === 0 ? 6 : 5,"offset-lg":_vm.col2.length === 0 ? 3 : 1}},_vm._l((_vm.col1),function(ref,index){
var label = ref.label;
var files = ref.files;
return _c('group-card',{key:'col1-' + index,attrs:{"label":label,"files":files},on:{"downloading":function (fIndex) { return _vm.setDownloading(1, index, fIndex, true); },"downloadFinished":function (fIndex) { return _vm.setDownloading(1, index, fIndex, false); }}})}),1):_vm._e(),(_vm.col2.length > 0)?_c('v-col',{attrs:{"cols":12,"md":"6","lg":"5"}},_vm._l((_vm.col2),function(ref,index){
var label = ref.label;
var files = ref.files;
return _c('group-card',{key:'col1-' + index,attrs:{"label":label,"files":files},on:{"downloading":function (fIndex) { return _vm.setDownloading(2, index, fIndex, true); },"downloadFinished":function (fIndex) { return _vm.setDownloading(2, index, fIndex, false); }}})}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }